import {reportError} from './failbot'

window.addEventListener('error', event => {
  if (event.error) {
    reportError(event.error)
  }
})

window.addEventListener('unhandledrejection', async event => {
  if (!event.promise) return
  try {
    await event.promise
  } catch (error) {
    reportError(error)
  }
})

if (window.location.hash === '#b00m') {
  setTimeout(() => {
    throw new Error('b00m')
  })
}
